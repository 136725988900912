<template>
    <div>
        <seasonList :sport_id="sport_id"></seasonList>
    </div>
</template>

<script>

import seasonList from '@/components/ballsports/season/list.vue';

export default {
    components: {
        seasonList
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 17,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>